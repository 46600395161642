@import "../../constants.scss";

.screen-manitoba-hc {
  p,
  label {
    font-family: "Open Sans", sans-serif;
  }

  p {
    font-size: 16px;
    color: $blue_med;
    margin-bottom: 16px;
  }

  label,
  .label {
    font-style: italic;
    font-size: 14px;
    color: $blue_med;
    margin-bottom: 0px;
  }

  .MuiFormControl-marginDense {
    margin-top: 0px;
  }

  .MuiFormLabel-asterisk {
    color: $red;
  }

  .MuiFormControl-root {
    display: block;
    margin-bottom: 16px;
  }
}
