@import '../../constants.scss';
.meeting-panel {
  display: flex;
  flex: 1;

  .panel-footer {
    .text-description {
      text-align: left;
    }
    .MuiButton-contained {
      background-color: white;
      color: #26436e;
      padding: 9px;
      padding-right: 15px;
      padding-left: 15px;
      font-size: 14px;
    }
  }
}
